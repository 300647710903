<template>
  <div class="oauth-authenticating">
    <h4 class="card-title">{{ $t(title) }}</h4>
    <p>
      {{ $t(message) }}
    </p>
  </div>
</template>

<script>
import authenticationService from "@/core/services/AuthenticationService";
import store from "@/core/store/PersistentStorage";
import router from '@/core/router';

export default {
  data() {
    return {
      title: 'pages.arcid.authenticating',
      message: 'pages.arcid.pleaseWait'
    }
  },
  methods: {
    login() {
      var authorizationCode = this.$route.query.code;
      if (authorizationCode) {
        try {
          authenticationService.loginSSO(authorizationCode).then(() => {
            if (this.$route.query.state) {
              // Go to destination set by SSO link
              const urlObj = new URL(this.$route.query.state) 
              if (urlObj) {
                router.push({
                  path: urlObj.pathname
                })
              }
            }
            else {
              // Redirect the user to the originally requested resource, or to the home view
              router.push(this.$route.query.redirect || '/');
            }
          }).catch((err) => {
            console.log("Failed - ", err);
          });
        } catch (err) {
          this.title = "pages.arcid.unable";
          this.message = "pages.arcid.somethingWrong";
        }
      } else {
        this.title = "pages.arcid.unable";
        this.message = "pages.arcid.badCode";
      }
    }
  },
  mounted() {
    var authorizationCode = this.$route.query.code;
    if (store.authentication.user) {
      // If the URL has an auth code in it, we should log the user out but not redirect them to ARC ID, because
      // there is already a code in the URL. This is a pseudo-SSO mechanism used by links in Schoolpace.
      if (authorizationCode) {
        // FIXME / TODO: Clear the store, log the user out.
        authenticationService.clearStore();
      }
    }
    this.login();
  }
}
</script>